import styled from 'styled-components';
import MedicalInfoContent from '@/components/medicalInfo/MedicalContent';
import Footer from '@/components/common/Footer';
import RenewalNotification from '@/components/common/RenewalNotification';

function Medical() {
  return (
    <Container>
      <RenewalNotification />
      <MedicalInfoContent />
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

export default Medical;
