import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import Inko from 'inko';
import { useDispatch } from 'react-redux';

import { birthMonthList } from '@/constants/refund';
import { attachedHyphensDate } from '@/util/format';
import { updateRefundInfo } from '@/store/modules/refund';
import { getNumberFullTime } from '@/util/getCurrentTime';
import { useAppSelector } from './useReduxHooks';

type ExpireDateType = {
  year: string;
  month: { value: string; label: string };
  day: string;
};

const useScanPassportInfo = () => {
  const inko = new Inko();
  const { temporaryStorage } = useAppSelector((state) => state.refund);
  const dispatch = useDispatch();
  const [touristInfo, setTouristInfo] = useState({
    supplyDate: attachedHyphensDate(getNumberFullTime(new Date())),
  });
  const [expireDate, setExpireDate] = useState<ExpireDateType>({
    year: '',
    month: { value: '', label: '' },
    day: '',
  });

  const { setValue, setFocus, clearErrors, setError } = useFormContext();

  // 임시저장 값 불러오기
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      const [monthValue] = birthMonthList.filter(
        (item) => item.value === temporaryStorage.birthMonth,
      );
      setExpireDate({
        year: temporaryStorage.birthYear || '',
        month: monthValue || '',
        day: temporaryStorage.birthDay || '',
      });
      setValue('birthYear', temporaryStorage.birthYear);
      setValue('birthMonth', monthValue);
      setValue('birthDay', temporaryStorage.birthDay);
    }
  }, [temporaryStorage]);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = e;
    const { name, value } = target;
    const koreanRegex = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
    if (koreanRegex.test(value)) {
      setError(name, { message: '영문으로 입력해주세요.' });
    } else {
      clearErrors(name);
    }

    if (name === 'nationality') {
      const numberRegex = /^[a-zA-Z]*$/g;
      if (!numberRegex.test(value)) {
        setError(name, { message: '영문으로 입력해주세요.' });
      }
    }

    if (name === 'passportNumber') {
      setValue(name, value.replace(' ', ''));
    } else {
      setValue(name, value.trimStart());
    }
    if (value.includes('<')) {
      extractData(value);
    }
  };
  const handleDebounceInput = handleInput;

  const extractData = (rawMrz: string) => {
    const mrz = inko.ko2en(rawMrz).toUpperCase();
    if (mrz.length !== 88) {
      return;
    }
    clearErrors('touristName');

    const docuReg = /[A-Z0-9<«]{7,15}([A-Z]{3}|D<<)(\d{7})[M|F]/;
    const rawDocuments = mrz.match(docuReg)?.[0].substr(1);

    if (rawDocuments) {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear().toString().slice(2, 4);
      const newMonth = birthMonthList.find(
        (month) => month.value === rawDocuments.substr(-6, 2),
      );

      setValue(
        'birthYear',
        rawDocuments.substr(-8, 2) > currentYear
          ? rawDocuments.substr(-8, 2).padStart(4, '19')
          : rawDocuments.substr(-8, 2).padStart(4, '20'),
      );
      setValue('birthDay', rawDocuments.substr(-4, 2));
      setValue('birthMonth', newMonth);
      setValue(
        'passportNumber',
        rawDocuments.substr(0, rawDocuments.length - 12).replace(/[<|«]/g, ''),
      );

      const firstName = mrz
        .substr(5, 39)
        .replace(/<<([A-Z]+)<</, '')
        .replace(/</g, '');
      const lastName = mrz.substr(5, 39).match(/<<([A-Z]+)<</)?.[1] || '';
      setValue('touristName', firstName + ' ' + lastName);

      setValue(
        'nationality',
        rawDocuments.substr(-11, 3).replace(/[<|«]/g, ''),
      );

      setExpireDate((prev) => ({
        ...prev,
        year:
          rawDocuments.substr(-8, 2) > currentYear
            ? rawDocuments.substr(-8, 2).padStart(4, '19')
            : rawDocuments.substr(-8, 2).padStart(4, '20'),
        month: newMonth !== undefined ? newMonth : prev.month,
        day: rawDocuments.substr(-4, 2),
      }));
      setFocus('nationality');
      dispatch(updateRefundInfo({ isScanned: true }));
    }
  };

  const createFormattedBirthDate = () => {
    const { year, month, day } = expireDate;
    const newBirthDay = day.padStart(2, '0');
    return attachedHyphensDate(year + month.value + newBirthDay);
  };

  return {
    expireDate,
    setExpireDate,
    onChange: handleDebounceInput,
    createFormattedBirthDate,
    touristInfo,
    setTouristInfo,
  };
};

export default useScanPassportInfo;
