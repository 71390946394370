import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from 'react-query';

import { routes } from '@/routes';

function Redirect() {
  const location = useLocation();
  const navigate = useNavigate();
  const isLogin = localStorage.getItem('loginToken');

  const fetchManifest = async () => {
    const response = await axios.get(
      'https://s3.ap-northeast-2.amazonaws.com/medical.ktaxpay.com/manifest.json',
    );
    return response.data;
  };

  useQuery(['manifest', location], fetchManifest, {
    onSuccess: (res) => {
      const currentVersion = localStorage.getItem('webVersion');
      const newVersion = res.web_version;
      if (currentVersion !== newVersion) {
        localStorage.setItem('webVersion', newVersion);
        window.location.reload();
      }
    },
    staleTime: 0,
  });

  // 로그인이 안되어 있거나, routes의 포함되지 않는 Url 입력시 기본 Url 이동
  useEffect(() => {
    // if (location.pathname.includes('/auto-login')) {
    //   const token = location.pathname.substring('/auto-login/'.length);
    //   navigate('/auto-login', { state: { token } });
    // } else if (
    //   Boolean(!isLogin) ||
    //   !Object.values(routes).includes(location.pathname)
    // ) {
    //   navigate('/');
    // }
    window.location.href = 'https://ktpmedical.ktaxpay.com';
  }, []);

  return null;
}

export default Redirect;
