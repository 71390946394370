import styled from 'styled-components';

import AlertIcon from '@/assets/common/error_white.png';
import FlexWrap from '@/components/common/FlexWrap';
import NoticeOldVersion from '../home/NoticeOldVersion.modal';
import { useState } from 'react';

function RenewalNotification() {
  const accessToken = localStorage.getItem('loginToken');
  const [isOpenNotice, setIsOpenNotice] = useState(false);

  return (
    <div>
      <ToastMessage>
        <FlexWrap alignItems='center' gap='12px'>
          <Image src={AlertIcon} />
          <TextWrapper>
            <ToastText>
              2024년 11월 1일부터 KTP 환급웹 구버전 서비스 지원이 종료됩니다.
            </ToastText>
            <SubText>
              서비스 이용 유지를 위해 오른쪽 버튼을 눌러 신버전을 사용해주세요.
            </SubText>
          </TextWrapper>
        </FlexWrap>
        <LinkBtn onClick={() => setIsOpenNotice(true)}>신버전 사용하기</LinkBtn>
      </ToastMessage>
      {isOpenNotice && (
        <NoticeOldVersion onClose={() => setIsOpenNotice(false)} />
      )}
    </div>
  );
}

const ToastMessage = styled.div`
  width: 100%;
  background-color: #ed0828;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 40px;
  font-weight: bold;
  animation: blink 1.5s infinite alternate;

  @keyframes blink {
    from {
      opacity: 0.2;
    }
    to {
      opacity: 1;
    }
  }
`;
const TextWrapper = styled.p`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
const ToastText = styled.span`
  color: #fff;
  margin-top: 2px;
  font-size: 21px;
`;
const SubText = styled.span`
  color: #fff;
  font-size: 18px;
  font-weight: 500;
`;
const Image = styled.img`
  width: 24px;
  height: 24px;
`;
const LinkBtn = styled.button`
  background-color: #246cf6;
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  font-size: 18px;
  :hover {
    background-color: #83a5e3;
  }
`;
export default RenewalNotification;
