import { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import RefundContent from '@/components/refund/RefundContent';
import RefundComplete from '@/components/refund/PaymentComplete/RefundComplete';
import Footer from '@/components/common/Footer';
import RenewalNotification from '@/components/common/RenewalNotification';
import { useAppSelector } from '@/hooks/useReduxHooks';
import PriceCheckModal from '@/components/refund/PriceCheckModal';
import CustomModal from '@/components/common/CustomModal';
import LoadingView from '@/components/common/LoadingView';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import NoticeOldVersion from '@/components/home/NoticeOldVersion.modal';

function Refund() {
  const { isExpired } = useGetGlobalStatus();
  const { modal, loading } = useAppSelector((state) => state);
  const [isRefund, setIsRefund] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isOpenNotice, setIsOpenNotice] = useState(true);

  useEffect(() => {
    setIsActive(modal.isActive);
  }, [modal]);

  return (
    <Fragment>
      <Container>
        {isRefund ? (
          <RefundComplete />
        ) : (
          <>
            <RenewalNotification />
            <RefundContent setIsRefund={setIsRefund} isExpired={isExpired} />
          </>
        )}
        <Footer />
      </Container>
      {isActive && modal?.type === 'CUSTOM' && modal?.isActive && (
        <CustomModal {...modal?.data} />
      )}
      {isActive && modal?.type === 'PRICE_CHECK' && modal?.isActive && (
        <PriceCheckModal {...modal?.data} />
      )}
      {loading.isLoading && (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      )}
      {isOpenNotice && (
        <NoticeOldVersion onClose={() => setIsOpenNotice(false)} />
      )}
    </Fragment>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1200px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const LoadingContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Refund;
