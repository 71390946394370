import styled from 'styled-components';
import HomeContent from '@/components/home/HomeContent';
import Footer from '@/components/common/Footer';
import RenewalNotification from '@/components/common/RenewalNotification';
import { useState } from 'react';
import NoticeOldVersion from '@/components/home/NoticeOldVersion.modal';

function Home() {
  const [isOpenNotice, setIsOpenNotice] = useState(true);

  return (
    <Container>
      <RenewalNotification />
      <HomeContent />
      <Footer />
      {isOpenNotice && (
        <NoticeOldVersion onClose={() => setIsOpenNotice(false)} />
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.blueColors.scale3};
  min-width: 1200px;
  height: 100vh;
  flex: 1;
  overflow: auto;
  position: relative;
`;

export default Home;
